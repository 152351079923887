import styled from 'styled-components';
import { darken } from 'polished';
import { Switch } from '@mui/material';

import { LAYOUT } from '../../appConfig';

const { COLORS } = LAYOUT;

const ButtonBarMStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;

  // define a largura dos elementos conforme parametro passado
  width: ${(props) => (props.width ? props.width : '100%')};
  // aplica a altura conforme parametro passado
  height: ${(props) => (props.height ? props.height : '100%')};
  // aplica a cor de fundo conforme parametro passado. Se não passar, não aplica
  background-color: ${(props) => (props.colorBg ? props.colorBg : 'transparent')};
  // aplica a cor dos elementos conforme parametro passado. Se não passar, aplica padrão
  color: ${(props) => (props.color ? props.color : COLORS.app.greyText)};

  .button-bar-itens {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    opacity: 0.6;
    transition: opacity 0.3s;

    .button-bar-icon,
    .button-bar-label {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button-bar-icon {
      height: 30px;
    }

    .button-bar-label {
      height: 15px;
      font-size: ${(props) => (props.labelSize ? props.labelSize : '12px')};
    }

    // aplica a próxima classe ao elemento somente quando receber o parametro isActive
    ${(props) => props.isActive && `opacity: 1;`}
  }
`;

const ButtonFlatStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  line-height: 1.1;
  text-align: center;

  // aplica padding conforme parametro passado
  padding: ${(props) => (props.padding ? props.padding : '6px')};
  // aplica tamanho da fonte conforme parametro passado
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  // aplica margin conforme parametro passado
  margin: ${(props) => (props.margin ? props.margin : '0')};
  // define a largura dos elementos conforme parametro passado
  width: ${(props) => (props.width ? props.width : '100%')};
  // aplica a altura conforme parametro passado
  height: ${(props) => (props.height ? props.height : '100%')};
  // aplica a cor dos elementos conforme parametro passado. Se não passar, aplica padrão
  color: ${(props) => (props.color ? props.color : COLORS.app.greyText)};
  // aplica a cor de fundo conforme parametro passado. Se não passar, não aplica
  background-color: ${(props) => (props.colorBg ? props.colorBg : 'transparent')};
  // aplica o border-radius conforme parametro passado. Se não passar, não aplica
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '0')};

  // altera o background color somente quando o elemento possuir a propriedade isPressed
  ${(props) => props.isPressed && props.colorBg && `background-color: ${darken(0.1, props.colorBg)};`}

  .button-content {
    //remove eventos de click para dispositivos móveis
    touch-action: none;
    pointer-events: none;
  }
`;

const ModalMStyled = styled.div`
  padding: ${(props) => (props.padding ? props.padding : '18px')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => (props.width ? props.width : '70%')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  background-color: ${(props) => (props.color ? props.color : 'white')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  // aplica sombra no modal com efecto de profundidade
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
`;

const SwitchMStyled = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(() => {
  return {
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#E9E9EA',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26, // / 2,
      height: 26,
      position: 'absolute',
      width: 42,
      top: 0,
      left: 0,
      backgroundColor: '#C0C0C0',
      opacity: 1,
    },
  };
});

const ButtonFieldRightStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 18px;
  /* border: 1px solid red; */
`;

export { ButtonBarMStyled, ModalMStyled, ButtonFlatStyled, SwitchMStyled, ButtonFieldRightStyled };
