import React from 'react';
import { useSelector } from 'react-redux';

import { selectProfileData } from '../../stores/profileSlice';

import { Drawer, Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import LogoMM from '../misc/LogoMM';
import { appVersion, LAYOUT, EVENTS } from '../../appConfig';
import { RiCloseLine } from 'react-icons/ri';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { DrawerListStyled, DrawerHeaderStyled, DrawerFooterStyled } from './MainContentStyled';

const { SIZES } = LAYOUT;
const { globalEvents } = EVENTS;

const sideBarWidth = 300;

const SideBarMenu = ({ isOpen, sections, onClose }) => {
  const profileData = useSelector(selectProfileData);

  const onNavigate = (section) => {
    if (section.externalLink) {
      const { externalLink } = section;
      let param = '';

      if (externalLink.param) {
        if (externalLink.param === 'cnpj') param = '/' + profileData.cnpj;
      }

      window.open(externalLink.url + param, '_blank');
    } else {
      const { id } = section;
      globalEvents.navigateTo(id);
    }

    onClose();
  };

  const onLogout = () => {
    globalEvents.logOut();
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onClose}
    >
      <Box
        sx={{ width: sideBarWidth, position: 'relative' }}
        role="presentation"
      >
        {/* Cabeçalho */}
        <DrawerHeaderStyled>
          <div className="drawer-header-avatar">
            <div
              className="drawer-close-bt"
              onClick={onClose}
            >
              <RiCloseLine size={'35px'} />
            </div>
            <div className="drawer-app-version">v{appVersion ?? '1.0.0'}</div>
            <div className="drawer-logo">
              <LogoMM />
            </div>
          </div>
          <div className="drawer-header-bar"></div>
        </DrawerHeaderStyled>

        {/* Menu */}
        <DrawerListStyled>
          <List>
            {sections.map((section) => (
              <ListItem
                key={section.id}
                button
                onClick={() => onNavigate(section)}
              >
                <ListItemIcon style={{ minWidth: 0 }}>{section.icon}</ListItemIcon>
                <ListItemText
                  style={{ paddingLeft: 15 }}
                  primary={section.fullTitle}
                />
              </ListItem>
            ))}
          </List>
        </DrawerListStyled>

        {/* Footer */}
        <DrawerFooterStyled
          width={sideBarWidth + 'px'}
          onClick={onLogout}
        >
          <div className="drawer-footer-icon">
            <RiLogoutBoxLine size={SIZES.icons} />
          </div>
          <div className="drawer-footer-content">Desconectar</div>
        </DrawerFooterStyled>
      </Box>
    </Drawer>
  );
};

export default SideBarMenu;
