import React, { useState, useEffect, useCallback } from 'react';

import { CardPackageStyled } from './CreditsBuyStyled';

const calcUnitPrice = (totalPrice, totalAmount) => {
  // totalPrice - preço em formato float (ex: 950.00)
  // totalAmount - total de itens (ex: 120)

  const unitPrice = totalPrice / totalAmount;
  const roundedPrice = Math.round(unitPrice * 100) / 100;

  // Converter para string com formato brasileiro (ponto para milhar, vírgula para decimal)
  const formattedPrice = roundedPrice.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedPrice;
};

const CardPackage = ({ data, onClick }) => {
  const [credits, setCredits] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [moves, setMoves] = useState(0);
  const [priceMain, setPriceMain] = useState(0);
  const [priceCents, setPriceCents] = useState(0);
  const [isHighlight, setIsHighlite] = useState(false);
  const [unitPrice, setUnitPrice] = useState(0);

  useEffect(() => {
    if (data) {
      const { creditos, bonus, ateMudancas, precoReais, precoCents, destaque } = data;

      setCredits(creditos);
      setBonus(bonus);
      setMoves(ateMudancas);
      setPriceMain(precoReais);
      setPriceCents(precoCents);
      setIsHighlite(destaque === 1);

      setUnitPrice(calcUnitPrice(parseFloat(precoReais.replace('.', '') + '.' + precoCents), creditos + bonus));
    }
  }, [data]);

  return (
    <CardPackageStyled
      isHighlight={isHighlight}
      onClick={() => {
        onClick(data?.id);
      }}
    >
      {isHighlight && <div className="card-highlight corner2-tl corner2-tr">MAIS ECONOMIA</div>}

      <div className={`card-header ${!isHighlight && 'corner-tl corner-tr'} font-16`}>
        <div
          className={`header-info-value ${!isHighlight && 'corner-tl'} w-${
            bonus ? 'half' : `full ${!isHighlight && 'corner-tr'}`
          }`}
        >
          <span className="bold mr-6 font-24">{credits}</span> <span className="pt-4">CRÉDITOS</span>
        </div>
        {bonus ? (
          <div className={`header-info-bonus w-half ${!isHighlight && 'corner-tr'}`}>
            <span className="bold mr-6 font-24">+{bonus}</span> <span className="pt-4">DE BÔNUS</span>
          </div>
        ) : null}
      </div>

      <div className="card-body">
        <div className="body-info-move">
          <div className="w-100 text-right font-16">veja até</div>
          <div className="font-75 bold mx-10 lineh-10 letter-space-small">{moves}</div>
          <div className="w-100">
            <div className="font-14 lineh-10">pedidos de</div>
            <div className="font-16">mudança*</div>
          </div>
        </div>

        <div className="body-info-price">
          <div className="w-100 text-right font-18">R$</div>
          <div className="font-35 bold ml-5 lineh-10 z-100">{priceMain},</div>
          <div className="w-100 font-18">{priceCents}</div>
          {isHighlight && (
            <div className="info-price-splash bold">
              <div className="font-30">{unitPrice}</div>
              <div className="font-12">por crédito</div>
            </div>
          )}
        </div>
      </div>

      <div className={`card-footer corner${isHighlight ? '2' : ''}-bl corner${isHighlight ? '2' : ''}-br font-26 bold`}>
        <div className="card-triangle"></div>
        <div className="h-42 pt-4">Comprar</div>
      </div>
    </CardPackageStyled>
  );
};

export default CardPackage;
