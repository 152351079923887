import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { GiHamburgerMenu } from 'react-icons/gi';

import Header from '../Header';

import useBlockTouchMove from '../../uteis/useBlockTouchMove';
import { selectProfileData } from '../../stores/profileSlice';
import { LAYOUT, EVENTS } from '../../appConfig';
import { MainContentStyled } from './MainContentStyled';

import SideBarMenu from './SideBarMenu';
import FooterMenu from './FooterMenu';
import LeadContentWindow from './LeadContentWindow';

const { COLORS, SIZES } = LAYOUT;
const { reloadLeads } = EVENTS.globalEvents;

const MainContent = ({ sectionsConfig, section, children, colorBg }) => {
  const hRef = useRef(null);
  const cRef = useRef(null);
  const fRef = useRef(null);
  useBlockTouchMove(hRef);
  useBlockTouchMove(cRef, true);
  useBlockTouchMove(fRef);

  const sectionList = orderBy(sectionsConfig, ['order'], ['asc']);
  const profileData = useSelector(selectProfileData);

  const [isOpenSideBarMenu, setIsOpenSideBarMenu] = useState(false);

  const onToggleSideBarMenu = () => {
    setIsOpenSideBarMenu(!isOpenSideBarMenu);
  };

  return (
    <>
      <LeadContentWindow />
      <SideBarMenu
        isOpen={isOpenSideBarMenu}
        sections={sectionList}
        onClose={onToggleSideBarMenu}
      />
      <MainContentStyled colorBg={colorBg}>
        <div
          className="maincontent-header"
          ref={hRef}
        >
          <Header
            bgColor={COLORS.app.blue}
            title={sectionsConfig[section].fullTitle}
            iconButton={<GiHamburgerMenu size={SIZES.icons} />}
            onClickButton={onToggleSideBarMenu}
            addOn={
              sectionsConfig[section]?.addOn
                ? {
                    icon: sectionsConfig[section].addOn.icon,
                    onClick: () => {
                      reloadLeads(profileData);
                    },
                  }
                : null
            }
          />
        </div>
        <div
          className="maincontent-content"
          ref={cRef}
        >
          {children}
        </div>
        <div
          className="maincontent-footer"
          ref={fRef}
        >
          <FooterMenu
            sections={sectionList.filter((section) => section.addOnFooter)}
            sectionId={section}
          />
        </div>
      </MainContentStyled>
    </>
  );
};

export default MainContent;
