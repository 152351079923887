import React, { useState, useEffect, useCallback } from 'react';

import { HeaderLeadsCreditsStyled } from './LeadsStyled';
import { ButtonFlatM } from '../../components/misc/muimm';

import { LAYOUT, LEADS } from '../../appConfig';

const { COLORS } = LAYOUT;

const configBtsFlat = {
  color: COLORS.app.white,
  width: '155px',
  height: '35px',
  fontSize: '13px',
  margin: '0 5px',
  padding: '6px',
  borderRadius: '5px',
};

const HeaderCreditsInfos = ({ creditBalance, onClickLeads, onClickBuyCredits, filterData }) => {
  return (
    <HeaderLeadsCreditsStyled>
      <div className="header--total-credits">
        <div className="header-credits--info">
          Saldo Atual:{' '}
          <span className={'info-value ' + (creditBalance < LEADS.minCreditsAlert ? 'value-alert' : '')}>
            {creditBalance} crédito{creditBalance === 1 ? '' : 's'}
          </span>
        </div>
      </div>

      <div className={`header-buttons--container ${filterData ? '' : 'add-pb'}`}>
        <ButtonFlatM
          {...configBtsFlat}
          colorBg={COLORS.components.orange_2}
          onClick={() => {
            onClickLeads && onClickLeads();
          }}
          disabled={!onClickLeads}
        >
          Buscar novos pedidos
        </ButtonFlatM>

        <ButtonFlatM
          {...configBtsFlat}
          colorBg={COLORS.components.violet_4}
          onClick={() => {
            onClickBuyCredits && onClickBuyCredits();
          }}
          disabled={!onClickBuyCredits}
        >
          Comprar créditos
        </ButtonFlatM>
      </div>

      {filterData && (
        <div className="header-tabs--container mt-15">
          {filterData.list.map((filter) => (
            <div
              key={filter.type}
              className={`header-tabs--tab ${filter.type === filterData.selected ? 'is-active' : ''}`}
              onClick={() => {
                filterData.onChange(filter.type);
              }}
            >
              {filter.text}
            </div>
          ))}
        </div>
      )}
    </HeaderLeadsCreditsStyled>
  );
};

export default HeaderCreditsInfos;
