import React from 'react';
import { useSelector } from 'react-redux';

import { SECTIONS_DATA, EVENTS } from '../../appConfig';
import { selectCreditsPackages, selectCurrentBalance, selectProfileData } from '../../stores/profileSlice';
import { pwaToExternalPage } from '../../uteis/helpers';

import HeaderCreditsInfos from '../Leads/HeaderCreditsInfos';
import { CreditsBuyContainerStyled } from './CreditsBuyStyled';
import logoPs from '../../assets/images/logopagseguro.png';
import CardPackage from './CardPackage';
import API from '../../services';

const { SECTIONS } = SECTIONS_DATA;
const { globalEvents } = EVENTS;

const CreditsBuy = () => {
  const packages = useSelector(selectCreditsPackages);
  const profileData = useSelector(selectProfileData);
  const creditBalance = useSelector(selectCurrentBalance);

  // console.log('<CreditsBuy> packages', packages);

  const onOpenLeads = () => {
    globalEvents.navigateTo(SECTIONS.Leads);
  };

  const onByCredits = async (packId) => {
    if (!packId) return;

    // abre modal de conexão com o PagSeguro
    globalEvents.startBuyCredits();

    // Inicia a conexão com o PagSeguro
    const result = await API.buyCredits(profileData, packId);

    if (result.erro) {
      globalEvents.errorBuyCredits(result.erro);
      return;
    }

    if (result?.checkoutUrl?.indexOf('ERR') === -1) {
      // window.location.href = result.checkoutUrl;
      pwaToExternalPage.go(result.checkoutUrl);
      setTimeout(() => {
        globalEvents.finishBuyCredits();
      }, 1000);
    }
  };

  return (
    <CreditsBuyContainerStyled>
      <div className="creditsbuy-container--content">
        <HeaderCreditsInfos
          creditBalance={creditBalance}
          onClickLeads={onOpenLeads}
        />

        <div className="content-cb--header color-bg-white pt-20">
          <div className="cb-title mb-20">
            Escolha o melhor pacote de
            <br /> créditos para você.
          </div>
          <div className="cb-subtitle mb-20">
            Sem pagamento mensal: você só paga
            <br /> pelos pedidos que interessam.
          </div>
          <div className="cb-text px-20">
            O <b>Muda Muda</b> funciona por um sistema exclusivo de créditos: <b>você só paga pelo que visualiza</b>! E
            é você quem escolhe quais pedidos vai querer ver. 
            <b>Escolha aqui o pacote de créditos que mais se adapta à sua necessidade, e bons negócios!</b>
          </div>
        </div>

        {/* Cards de compras */}
        <div className="content-cb--itens color-bg-white p-20">
          {packages?.map((o, ox) => (
            <CardPackage
              key={'pi-' + ox}
              data={o}
              onClick={onByCredits}
            />
          ))}
        </div>

        {/* Rodapé */}
        <div className="content-cb--footer w-full pl-20 pr-20 pt-20">
          <div className="w-full flex flex-nowrap justify-space-between items-center">
            <div className="w-160 d-flex cb-text">
              <b>Pague com</b>
            </div>
            <div className="flex-1 flex flex-center">
              <img
                src={logoPs}
                width="100%"
                height="auto"
                alt="logo-pagseguro"
              />
            </div>
          </div>

          <div className="w-full cb-text">
            <p>
              Caso efetue a <span className="text-underline">compra por cartão de crédito ou por PIX</span>, seu saldo
              de créditos <b>será atualizado em alguns minutos.</b>
            </p>

            <p>
              Caso efetue a <span className="text-underline">compra por boleto</span>, os créditos adquiridos só poderão
              ser usados <b>após a compensação pelo sistema bancário</b> (que acontece normalmente de{' '}
              <span className="text-underline">24 a 48 horas úteis após o pagamento</span>).
            </p>

            <div className="mt-40">
              <ul className="px-20">
                <li className="mb-8">
                  <i>
                    Cada visualização de pedido vale 01, 02 ou 03 créditos, dependendo da origem e destino da mudança.
                  </i>
                </li>
                <li className="mb-8">
                  <i>
                    Ver a data de uma mudança e as cidades de origem e destino não custa nada. Você só usa seus créditos
                    se decidir visualizar os dados de contato da pessoa que deseja se mudar e as características da sua
                    mudança.
                  </i>
                </li>
                <li className="mb-8">
                  <i>
                    Depois de visualizar um pedido a primeira vez, você pode visualizá-lo de novo quantas vezes quiser.
                  </i>
                </li>
                <li className="mb-8">
                  <i>Os créditos que você compra valem por um ano: nesse período, você pode usá-los quando quiser.</i>
                </li>
                <li className="mb-8">
                  <i>
                    O pagamento pode ser feito por cartão de crédito, por PIX ou por boleto (neste último caso, é
                    necessário aguardar a compensação).
                  </i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </CreditsBuyContainerStyled>
  );
};

export default CreditsBuy;
