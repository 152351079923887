import React, { useEffect, useState } from 'react';
import { cloneDeep, isEqual } from 'lodash';

import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';

import { TbEye, TbEyeClosed } from 'react-icons/tb';

import LogoMM from '../../components/misc/LogoMM';
import { TextFieldM, ButtonM } from '../../components/misc/muimm';
import { LoginStyled, LoginFieldsStyled, LoginTextResponseStyled, LoginLoadingStyled } from './LoginStyled';
import { checkInput } from '../../uteis/validators';
import { LAYOUT, EVENTS } from '../../appConfig';

import { getAppToken } from '../../services/firebase';
import API from '../../services';

const { COLORS, SIZES } = LAYOUT;
const { globalEvents } = EVENTS;

const defaultTextResponse = {
  title: '',
  text: '',
};

const defaultFieldsErrors = {
  cnpj: {
    error: false,
    msg: {
      title: 'CNPJ Inválido',
      text: 'Verifique o CNPJ digitado!',
    },
  },
  password: {
    error: false,
    msg: {
      title: 'Senha Inválida',
      text: 'Digite uma senha com no mínimo 6 caracteres e sem espaços!',
    },
  },
  forgotPassword: {
    error: false,
    msg: {
      title: 'CNPJ Inválido',
      text: 'Para recuperar sua senha insira seu CNPJ corretamente!',
    },
  },
};

const LoginFields = ({ externalLogin }) => {
  const [cnpj, setCnpj] = useState('');
  const [password, setPassword] = useState('');
  const [textResponse, setTextResponse] = useState(defaultTextResponse);
  const [textRequest, setTextRequest] = useState('Por favor aguarde...');
  const [isLoading, setIsLoading] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [inputRequest, setInputRequest] = useState(false);
  const [fieldsErrors, setFieldsErrors] = useState(defaultFieldsErrors);
  const [passType, setPassType] = useState('password');

  useEffect(() => {
    if (isEqual(fieldsErrors, defaultFieldsErrors)) return;

    const { cnpj, password, forgotPassword } = fieldsErrors;

    if (cnpj.error || password.error || forgotPassword.error) {
      const fieldErr = cloneDeep(cnpj.error ? cnpj : password.error ? password : forgotPassword);
      setTextResponse((t) => ({ ...t, ...fieldErr.msg }));
      setInputError(true);
    } else {
      clearErrors();
    }
  }, [fieldsErrors]);

  useEffect(() => {
    if (externalLogin?.error) finishLogin(externalLogin.error);
  }, [externalLogin]);

  const clearErrors = () => {
    setInputError(false);
    setFieldsErrors((t) => ({ ...t, ...defaultFieldsErrors }));
  };

  const responseStatusError = (field, error = false) => {
    if (!field) {
      setFieldsErrors((t) => ({ ...t, ...defaultFieldsErrors }));
      return;
    }

    const ferr = cloneDeep(fieldsErrors);
    ferr[field] = { ...ferr[field], error };
    setFieldsErrors((t) => ({ ...t, ...ferr }));
  };

  const startLogin = (txtRequest) => {
    setIsLoading(true);
    setInputRequest(false);
    responseStatusError();
    clearErrors();
    setTextRequest(txtRequest ?? '');
  };

  const finishLogin = (error, txtResponse, keepLoading = true) => {
    setTextRequest('');

    if (!error) {
      setTextResponse((t) => ({
        ...t,
        title: txtResponse ? 'Solicitação concluída' : '',
        text: txtResponse ?? 'Por favor aguarde...',
      }));
      setInputRequest(true);
      setIsLoading(keepLoading);
      return;
    }

    setIsLoading(false);
    setInputRequest(false);
    setInputError(true);
    setTextResponse((t) => ({ ...t, title: 'Falha ao acessar conta!', text: error?.message ?? (txtResponse || '') }));
  };

  const onLogin = async () => {
    if (!checkInput.cnpj(cnpj)) {
      responseStatusError('cnpj', true);
      return;
    }

    if (!checkInput.simplePassword(password)) {
      responseStatusError('password', true);
      return;
    }

    startLogin('Aguarde, efetuando login...');

    // para o acesso recupera o token do firebase
    const fbAppToken = await getAppToken();
    const result = await API.validateLogin(cnpj, password, fbAppToken);

    if (result?.dadoslogin) {
      result.dadoslogin.senha = password;
      globalEvents.logIn(result);
    }

    finishLogin(result?.erro);
  };

  const onForgotPassword = async () => {
    if (!checkInput.cnpj(cnpj)) {
      responseStatusError('forgotPassword', true);
      return;
    }

    startLogin('Aguarde, enviando solicitação de recuperação de senha...');

    const result = await API.sendPassword(cnpj);

    let txtResponse = result?.email ? (
      <>
        A senha foi enviada para o e-mail cadastrado <b>{result.email}</b>
      </>
    ) : null;

    finishLogin(result?.erro, txtResponse, false);
  };

  return (
    <>
      <LoginFieldsStyled>
        <div className="title">Acesse sua conta ativa</div>
        <div className="text-info">
          Para iniciar o aplicativo entre com seus dados de <b>CNPJ</b> e <b>Senha</b> já cadastrados no site do{' '}
          <b>Muda Muda</b>
        </div>

        <TextFieldM
          id="standard-basic"
          value={cnpj}
          label="CNPJ"
          variant="standard"
          margin="normal"
          type="number"
          fullWidth
          css={{
            marginTop: '15px',
            backgroundColor: (fieldsErrors.cnpj.error || fieldsErrors.forgotPassword.error) && COLORS.form.fieldBgError,
          }}
          onChange={(e) => {
            setCnpj(e.target.value);
          }}
          onFocus={() => {
            responseStatusError();
          }}
          disabled={isLoading}
        />

        <TextFieldM
          id="standard-basic"
          label="Senha"
          variant="standard"
          margin="normal"
          value={password}
          type={passType}
          fullWidth
          css={{
            marginTop: '10px',
            backgroundColor: fieldsErrors.password.erro && COLORS.form.fieldBgError,
          }}
          btnRight={{
            iconActive: (
              <TbEye
                color={COLORS.app.greyTextButton}
                size={SIZES.icons}
              />
            ),
            iconInactive: (
              <TbEyeClosed
                color={COLORS.app.greyTextButton}
                size={SIZES.icons}
              />
            ),
            onClick: (status) => {
              setPassType(status ? 'text' : 'password');
            },
          }}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          onFocus={() => {
            responseStatusError();
          }}
          disabled={isLoading}
        />

        <div className="buttons">
          <ButtonM
            onClick={onForgotPassword}
            textColor={COLORS.app.greyTextButton}
            disabled={isLoading}
          >
            ESQUECI A SENHA
          </ButtonM>
          <ButtonM
            onClick={onLogin}
            disabled={isLoading}
          >
            ACESSAR
          </ButtonM>
        </div>
      </LoginFieldsStyled>

      {isLoading ? (
        <LoginLoadingStyled>
          <UseAnimations
            size={100}
            animation={loading}
            strokeColor={COLORS.app.white}
          />
          <div className="info">{textRequest}</div>
        </LoginLoadingStyled>
      ) : (
        (inputError || inputRequest) && (
          <LoginTextResponseStyled alert={inputError}>
            {textResponse.title !== '' && <div className="title">{textResponse.title}</div>}
            {textResponse.text}
          </LoginTextResponseStyled>
        )
      )}
    </>
  );
};

const Login = ({ externalLogin }) => {
  return (
    <LoginStyled>
      <div className="contents">
        <div className="logo-container">
          <LogoMM width={'200px'} />
        </div>
        <LoginFields externalLogin={externalLogin} />
      </div>
    </LoginStyled>
  );
};

export default Login;
