import styled from 'styled-components';

import { LAYOUT } from '../../../appConfig';

const { COLORS } = LAYOUT;

const ModalBasicStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => (props.height ? props.height : 'auto')};

  .modal-basic--icon {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${COLORS.components.orange_2};
  }

  .modal-basic--text {
    width: 100%;
    height: 120px;
    font-size: 18px;
    color: ${COLORS.app.greyText};

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    div {
      margin: 5px 0;
      text-align: center;
    }
  }

  .modal-basic--buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export { ModalBasicStyled };
