const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const loop = (init, end = null) => {
  if (end == null) {
    end = init;
    init = 0;
  }

  let arr = [];

  for (let i = init; i <= end; i++) arr.push(i);

  return arr;
};

const registerToExternalPage = 'registerToExternalPage';

const pwaToExternalPage = {
  go: (url) => {
    sessionStorage.setItem(registerToExternalPage, true);
    window.location.href = url;
  },
  isReturningToPWA: () => {
    if (!window) {
      console.error('Obejeto window nao encontrado!');
      return;
    }

    // eslint-disable-next-line
    const isReturning = sessionStorage.getItem(registerToExternalPage) == 'true';

    console.log(isReturning, sessionStorage.getItem(registerToExternalPage));

    if (isReturning) {
      sessionStorage.removeItem(registerToExternalPage);
    }

    return isReturning;
  },
};

const resetViewPort = (onlyIOS, callback) => {
  if (!onlyIOS || (onlyIOS && isIOS)) {
    const viewportMeta = document.createElement('meta');
    viewportMeta.name = 'viewport';
    viewportMeta.content =
      'initial-scale=1, user-scalable=no, width=device-width, height=device-height, viewport-fit=cover';
    document.head.appendChild(viewportMeta);

    setTimeout(() => {
      viewportMeta.content = 'initial-scale=1, width=device-width, viewport-fit=contain';

      if (callback) callback();
    }, 100);
  }
};

export { loop, pwaToExternalPage, resetViewPort };
