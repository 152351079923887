import styled from 'styled-components';

import splashSvg from '../../assets/images/splash.svg';

import { LAYOUT } from '../../appConfig';

const { COLORS } = LAYOUT;

const cornerR = '24px';
const cornerR2 = '18px';

const CreditsBuyContainerStyled = styled.div`
  width: 100%;
  color: ${COLORS.app.greyText};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 20px;

  .creditsbuy-container--content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .content-cb--header,
    .content-cb--footer {
      .cb-title,
      .cb-subtitle,
      .cb-text {
        width: 100%;
        font-size: 16px;
        line-height: 1.2;
      }

      .cb-title {
        color: ${COLORS.lead.green_1};
        font-weight: 700;
        font-size: 24px;
      }

      .cb-subtitle {
        color: 'black';
        font-weight: 700;
        line-height: 1.2;
      }

      .cb-text {
        color: ${COLORS.lead.grey_9};
      }
    }

    .content-cb--header,
    .content-cb--itens {
      background-color: '#FFFFFF';
      margin-top: -1px;
    }

    .content-cb--header {
      /* margin-bottom: 20px; */

      .cb-title,
      .cb-subtitle,
      .cb-text {
        text-align: center;
      }
    }

    .content-cb--itens {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .content-cb--footer {
    }
  }
`;

const CardPackageStyled = styled.div`
  width: 90%;
  min-height: 100px;
  margin: 20px 0;
  border-radius: ${cornerR};
  background-color: ${COLORS.lead.grey_10};
  box-shadow: 0px 0px 16px -3px rgba(0, 0, 0, 0.75);
  color: black;

  border: ${(props) => (props.isHighlight ? `5px solid ${COLORS.app.pink}` : `2px solid ${COLORS.app.white}`)};

  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */

  .corner {
    &-tl {
      border-top-left-radius: ${cornerR};
    }
    &-tr {
      border-top-right-radius: ${cornerR};
    }
    &-bl {
      border-bottom-left-radius: ${cornerR};
    }
    &-br {
      border-bottom-right-radius: ${cornerR};
    }
  }

  .corner2 {
    &-tl {
      border-top-left-radius: ${cornerR2};
    }
    &-tr {
      border-top-right-radius: ${cornerR2};
    }
    &-bl {
      border-bottom-left-radius: ${cornerR2};
    }
    &-br {
      border-bottom-right-radius: ${cornerR2};
    }
  }

  .card-highlight {
    flex: none;
    width: 100%;
    height: 40px;
    background-color: ${COLORS.app.pink};
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px -18px 23px -4px rgba(0, 0, 0, 0.35);
  }

  .card-header {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;

    .header-info-value,
    .header-info-bonus {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .header-info-bonus {
      background-color: ${COLORS.components.orange_3};
      color: white;
    }
  }

  .card-body {
    width: 100%;

    .body-info-move {
      padding: 10px 0;
      background-color: #444444;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .body-info-price {
      height: 33px;
      padding-top: 7px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .info-price-splash {
        position: absolute;
        width: 110px;
        height: 110px;
        top: -30px;
        right: -30px;
        z-index: 100;
        pointer-events: none;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1;

        background-image: url(${splashSvg});
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;

        // rotaciona o splash
        transform: rotate(-15deg);
        transform-origin: center center;

        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.67));
      }
    }
  }

  .card-footer {
    background-color: ${COLORS.app.pink};
    color: white;
    height: 60px;
    width: 100%;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    line-height: 1;

    .card-triangle {
      margin-top: -1px;
      flex: 1;
      width: 100%;
      background-color: ${COLORS.lead.grey_10};
      clip-path: polygon(-10px 0px, 104% 0px, 50% 100%);
    }
  }
`;

export { CreditsBuyContainerStyled, CardPackageStyled };
