// ícones
import { FaReceipt } from 'react-icons/fa';
import { MdToggleOn } from 'react-icons/md';
import { IoDocumentText } from 'react-icons/io5';
import { TbReload, TbCoin } from 'react-icons/tb';
import { MdWeb } from 'react-icons/md';

// especificações de cores, dimensionamento, etc

const appVersion = '1.0.0.056';

const COLORS = {
  app: {
    blue: '#463858',
    pink: '#ff1e66',
    greyBg: '#EAEAE9',
    greyText: '#565656',
    greyTextButton: '#929497',
    green: '#429488',
    redAlert: '#E82121',
    white: '#FFFFFF',
  },
  lead: {
    green_1: '#7AC743',
    green_2: '#A7CE8C',
    green_3: '#CFE1BB',
    grey_1: '#808081',
    grey_2: '#A4A4A4',
    grey_3: '#D2CFCF',
    grey_4: '#8E8A9A',
    grey_5: '#EDECED',
    grey_6: '#CCC8C8',
    grey_7: '#7A7A7A',
    grey_8: '#B6B6B6',
    grey_9: '#666666',
    grey_10: '#E1E1E1',
    violet_1: '#8272B3',
    violet_2: '#B4A6D0',
    violet_3: '#A072FA',
    violet_4: '#4c3df5',
    blue_alert: '#003DFF',
  },
  components: {
    violet_1: '#8272B3',
    violet_2: '#B4A6D0',
    violet_3: '#A072FA',
    violet_4: '#4c3df5',
    orange_1: '#DE9B44',
    orange_2: '#FFBC00',
    orange_3: '#FF963B',
  },
  form: {
    fieldBgError: '#FBEDED',
  },
};

const SIZES = {
  icons: '28px',
  screenWidth: '100vw',
  screenHeight: '100vh',
};

const LAYOUT = {
  COLORS,
  SIZES,
};

// URLs ////////////////////////////////////////////////
const HOSTS_TO_API = {
  // localhost: 'http://localhost/sites/mudamuda-2020-site/',
  localhost: 'https://homologacao.mudamuda.com.br/',
  '10.0.2.2': 'https://homologacao.mudamuda.com.br/',
  'homologacao.mudamuda-app.com.br': 'https://homologacao.mudamuda.com.br/',
  'mudamuda-app.com.br': 'https://mudamuda.com.br/',
  default: '',
};

const BASE_API_URL = HOSTS_TO_API[document.location.hostname] ?? HOSTS_TO_API.default;

const apiKey = 'ws-wvT79q7ox-k3RjpDZzLi-XjbYyO2Z6/pwa';

const baseUrlAPI = BASE_API_URL + apiKey;

const URL_API = {
  teste: baseUrlAPI + '/testepd',
  LOGIN: baseUrlAPI + '/login',
  REENVIA_SENHA: baseUrlAPI + '/mailsenha',
  LISTA_PEDIDOS: baseUrlAPI + '/lstpds',
  PEGA_SALDO: baseUrlAPI + '/pegasld',
  PEGA_STATUS_PEDIDO: baseUrlAPI + '/pegastpd',
  PEGA_PEDIDO: baseUrlAPI + '/pegapd',
  CARREGA_LOCAIS: baseUrlAPI + '/carrlcs',
  SALVA_LOCAIS: baseUrlAPI + '/slvlcs',
  CHK_CONNECT: baseUrlAPI + '/chkconn',
  COMPRA_CREDITOS: baseUrlAPI + '/comcrd',
};

const URL_SITE_LOGIN = BASE_API_URL + 'login';

const URLS = {
  BASE_API_URL,
  URL_API,
  URL_SITE_LOGIN,
};

/////////////////////////////
// Seções do app

const SECTIONS = {
  Login: 'Login_section',
  CreditsBuy: 'Credits_buy_section',
  Leads: 'Leads_section',
  Config: 'Config_section',
  Profile: 'Profile_section',
  Site_partner_external: 'Site_partner_external_section',
};

const SECTIONS_CONFIG = {
  [SECTIONS.Leads]: {
    order: 1,
    id: SECTIONS.Leads,
    title: 'Pedidos',
    fullTitle: 'Pedidos de Mudanças',
    icon: <FaReceipt size={SIZES.icons} />,
    addOn: {
      icon: <TbReload size={SIZES.icons} />,
    },
    colorBg: null,
    addOnFooter: true,
  },
  [SECTIONS.CreditsBuy]: {
    order: 2,
    id: SECTIONS.CreditsBuy,
    title: 'Comprar Créditos',
    fullTitle: 'Comprar Créditos',
    icon: <TbCoin size={SIZES.icons} />,
    colorBg: null, //COLORS.app.white,
  },
  [SECTIONS.Site_partner_external]: {
    order: 3,
    id: SECTIONS.Site_partner_external,
    title: 'Acessar site',
    fullTitle: 'Acessar site',
    icon: <MdWeb size={SIZES.icons} />,
    colorBg: null, //COLORS.app.white,
    externalLink: {
      param: 'cnpj',
      url: URLS.URL_SITE_LOGIN,
    },
  },
  [SECTIONS.Config]: {
    order: 4,
    id: SECTIONS.Config,
    title: 'Configuração',
    fullTitle: 'Configuração de Notificações',
    icon: <MdToggleOn size={SIZES.icons} />,
    colorBg: null,
    addOnFooter: true,
  },
  [SECTIONS.Profile]: {
    order: 5,
    id: SECTIONS.Profile,
    title: 'Cadastro',
    fullTitle: 'Dados de Cadastro',
    icon: <IoDocumentText size={SIZES.icons} />,
    colorBg: null,
    addOnFooter: true,
  },
};

const SECTIONS_DATA = {
  SECTIONS,
  SECTIONS_CONFIG,
};

/////////////////////////////
// STATUS GERAIS DE CONEXÃO
const CONNECTION_STATUSES = {
  sending: 'sending',
  sent: 'sent',
  error: 'error',
  offline: 'offline',
  online: 'online',
};

/////////////////////////////
// eventos globais

const EVENTS_TYPES = {
  navigateTo: 'navigateTo_EVENT',
  logIn: 'logIn_EVENT',
  logOut: 'logOut_EVENT',
  reloadLeads: 'reloadLeads_EVENT',
  openLeadDetails: 'openLeadDetails_EVENT',
  closeLeadDetails: 'closeLeadDetails_EVENT',
  mfcChannelFocus: 'mm-pwa-focus',
  mfcChannelOpen: 'mm-pwa-open',
  localNotificationReceived: 'localNotificationReceived_EVENT',
  connectToByCredits: 'connectToByCredits_EVENT',
  errorBuyCredits: 'errorBuyCredits_EVENT',
  finishBuyCredits: 'finishBuyCredits_EVENT',
};

const globalEvents = {
  navigateTo: (section) => {
    const event = new CustomEvent(EVENTS_TYPES.navigateTo, { detail: { sectionId: section } });
    document.dispatchEvent(event);
  },
  logIn: (data, autoLogin = false) => {
    const event = new CustomEvent(EVENTS_TYPES.logIn, { detail: { data, autoLogin } });
    document.dispatchEvent(event);
  },
  logOut: (byError = null) => {
    const event = new CustomEvent(EVENTS_TYPES.logOut, { detail: { byError } });
    document.dispatchEvent(event);
  },
  reloadLeads: (profileData) => {
    const event = new CustomEvent(EVENTS_TYPES.reloadLeads, { detail: { profileData } });
    document.dispatchEvent(event);
  },
  openLeadDetails: (leadId, cost, status) => {
    const event = new CustomEvent(EVENTS_TYPES.openLeadDetails, { detail: { leadId, cost, status } });
    document.dispatchEvent(event);
  },
  closeLeadDetails: () => {
    const event = new CustomEvent(EVENTS_TYPES.closeLeadDetails);
    document.dispatchEvent(event);
  },
  localNotificationReceived: (leadId, cityFrom, cityTo) => {
    const event = new CustomEvent(EVENTS_TYPES.localNotificationReceived, { detail: { leadId, cityFrom, cityTo } });
    document.dispatchEvent(event);
  },
  startBuyCredits: () => {
    const event = new CustomEvent(EVENTS_TYPES.connectToByCredits);
    document.dispatchEvent(event);
  },
  errorBuyCredits: (errorMessage) => {
    const event = new CustomEvent(EVENTS_TYPES.errorBuyCredits, { detail: { errorMessage } });
    document.dispatchEvent(event);
  },
  finishBuyCredits: () => {
    const event = new CustomEvent(EVENTS_TYPES.finishBuyCredits);
    document.dispatchEvent(event);
  },
};

const EVENTS = {
  EVENTS_TYPES,
  globalEvents,
};

/////////////////////////////
// Pedidos
const LEADS = {
  status: {
    not_viewed: 0,
    pre_viewed: 2,
    already_viewed: 1,
    in_limit: -1,
  },
  minCreditsAlert: 10,
};

// Storage /////////////////////////////////
const STORAGE_ALERTS = '@storage_alerts';
const STORAGE_PROFILE = '@storage_profile';

const PROFILE_DEFAULT_DATA = {
  isLogged: false,
  id: null,
  cnpj: '',
  password: '',
  local_1: { idl: 0, iduf: 0, idcdd: 0 },
  local_2: { idl: 0, iduf: 0, idcdd: 0 },
  local_3: { idl: 0, iduf: 0, idcdd: 0 },
  local_4: { idl: 0, iduf: 0, idcdd: 0 },
  local_5: { idl: 0, iduf: 0, idcdd: 0 },
};

const STORAGE = {
  STORAGE_ALERTS,
  STORAGE_PROFILE,
  PROFILE_DEFAULT_DATA,
};

const ERRORS = {
  PERFIL_NAO_INFORMADO: -100,
  PERFIL_INVALIDO: -101,
  PEDIDO_NAO_LOCALIZADO: -102,
  CREDITO_INSUFICIENTE: -103,
  PEDIDOS_NAO_CARREGADOS: -104,
};

// login para acesso do parceiro ao seu painel dentro do site

export { appVersion, LAYOUT, EVENTS, LEADS, URLS, STORAGE, ERRORS, SECTIONS_DATA, CONNECTION_STATUSES };
