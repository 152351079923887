import React from 'react';

import UseAnimations from 'react-useanimations';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';

import loading from 'react-useanimations/lib/loading';
import { ModalM, ButtonM } from '../../../components/misc/muimm';
import { ModalBasicStyled } from './ModalBasicStyled';
import { CONNECTION_STATUSES, LAYOUT } from '../../../appConfig';
const { COLORS } = LAYOUT;

const ModalBuyCredits = ({ isOpen, onClose, statusConnection, errorMessage }) => {
  return (
    <ModalM
      isOpen={isOpen}
      onClose={onClose}
      padding={'10px'}
    >
      <ModalBasicStyled>
        {statusConnection === CONNECTION_STATUSES.error && (
          <div className="modal-basic--icon h-70 mb-20">
            <BsFillExclamationTriangleFill size={40} />
          </div>
        )}
        <div className="modal-basic--text h-auto">
          {statusConnection === CONNECTION_STATUSES.error ? (
            <div className="lineh-13">
              <p className="mt-0 break-words break-all">{errorMessage || 'Erro desconhecido.'}</p>
              <p className="m-0 opacity-50">
                Por favor, tente
                <br />
                novamente mais tarde.
              </p>
            </div>
          ) : (
            statusConnection === CONNECTION_STATUSES.sending && (
              <div className="h-140 flex flex-column flex-center">
                <UseAnimations
                  animation={loading}
                  size={70}
                  strokeColor={COLORS.lead.green_1}
                />

                <div className="modal-confignot--text">
                  <div className="confignot-text--title lineh-13">Aguarde, conectando com PagSeguro...</div>
                </div>
              </div>
            )
          )}
        </div>
        {statusConnection === CONNECTION_STATUSES.error && (
          <div className="modal-basic--buttons items-center justify-end mt-5">
            <ButtonM onClick={onClose}>Fechar</ButtonM>
          </div>
        )}
      </ModalBasicStyled>
    </ModalM>
  );
};

export default ModalBuyCredits;
