import { createGlobalStyle } from 'styled-components';

const stls = [
  { type: 'height', prefix: 'h', length: 500 },
  { type: 'width', prefix: 'w', length: 500 },
  { type: 'padding', prefix: 'p', length: 200 },
  { type: 'padding-top', prefix: 'pt', length: 200 },
  { type: 'padding-bottom', prefix: 'pb', length: 200 },
  { type: 'padding-left', prefix: 'pl', length: 200 },
  { type: 'padding-right', prefix: 'pr', length: 200 },
  { type: 'margin', prefix: 'm', length: 200 },
  { type: 'margin-top', prefix: 'mt', length: 200 },
  { type: 'margin-bottom', prefix: 'mb', length: 200 },
  { type: 'margin-left', prefix: 'ml', length: 200 },
  { type: 'margin-right', prefix: 'mr', length: 200 },
];

const stlsMix = [
  { type: 'padding', prefix: 'px', axis: 'x', length: 200 },
  { type: 'padding', prefix: 'py', axis: 'y', length: 200 },
  { type: 'margin', prefix: 'mx', axis: 'x', length: 200 },
  { type: 'margin', prefix: 'my', axis: 'y', length: 200 },
];

export const DynamicGlobalStyles = createGlobalStyle`

  ${stls.map((s) => {
    return Array.from({ length: s.length }, (_, i) => i)
      .map(
        (size) => `
          .${s.prefix}-${size} {
            ${s.type}: ${size}px !important;
          }
        `
      )
      .join('');
  })}
  
  ${stlsMix.map((s) => {
    return Array.from({ length: s.length }, (_, i) => i)
      .map(
        (size) => `
          .${s.prefix}-${size} {
            ${s.type}: ${s.axis === 'x' ? `0 ${size}px` : `${size}px 0`} !important;
          }
        `
      )
      .join('');
  })}

  .w-full {
    width: 100%;
  }

  .w-half {
    width: 50%;
  }

  .h-auto {
    height: auto !important;
  }

  /* Flexbox Utilities */
  .flex {
    display: flex;
  }
  .flex-none {
    flex:none;
  }
  .flex-1 {
    flex-grow: 1;
  }
  .flex--1 {
    flex-shrink: 1;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-nowrap {
    flex-wrap: nowrap;
  }
  .flex-column {
    flex-direction: column;
  }
  .flex-row {
    flex-direction: row;
  }
  .flex-center {
    justify-content: center !important;
    align-items: center !important;
  }
  .justify-start {
    justify-content: flex-start !important;
  }
  .justify-end {
    justify-content: flex-end !important;
  }
  .justify-center {
    justify-content: center !important;
  }
  .justify-space-between {
    justify-content: space-between !important;
  }
  .justify-space-around {
    justify-content: space-around !important;
  }
  .justify-space-evenly {
    justify-content: space-evenly !important;
  }
  .items-start {
    align-items: flex-start !important;
  }
  .items-center {
    align-items: center !important;
  }
  .items-end {
    align-items: end !important;
  }
  .items-stretch {
    align-items: stretch !important;
  }
  .items-baseline {
    align-items: baseline !important;
  }

  /* z-indez e positions */

  ${[10, 50, 100, 500, 1000]
    .map(
      (size) => `
      .z-${size} {
        z-index: ${size * 10};
      }
    `
    )
    .join('')}

    .pos-relative {
      position: relative;
    }

    .pos-absolute {
      position: absolute;
    }

  /* Text */
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .text-underline {
    text-decoration: underline;
  }
  .bold {
    font-weight: 700;
  }

  .letter-space-small {
    letter-spacing: -5px;
  }

  ${Array.from({ length: 100 }, (_, i) => i + 5)
    .map(
      (size) => `
      .font-${size} {
        font-size: ${size}px;
      }
    `
    )
    .join('')}

${Array.from({ length: 10 }, (_, i) => i + 5)
  .map(
    (size) => `
      .opacity-${size * 10} {
        opacity: ${size / 10};
      }
    `
  )
  .join('')}

${Array.from({ length: 30 }, (_, i) => i)
  .map(
    (size) => `
      .lineh-${size} {
        line-height: ${size / 10};
      }
    `
  )
  .join('')}

  /* Cores */
  .color-bg-white {
    background-color: white;
  }

  /* Utilitários */
  .break-words {
    overflow-wrap: break-word;
    word-wrap: break-word; /* Para compatibilidade com navegadores antigos */
    word-break: break-word; /* Quebra palavras apenas quando necessário */
    hyphens: auto; /* Adiciona hífens onde apropriado quando quebra palavras */

    &.break-all {
      word-break: break-all; /* Quebra palavras em qualquer caractere */
    }
  }
`;
