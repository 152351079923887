import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectProfileData,
  selectCurrentBalance,
  selectLeadsTypeFilter,
  updateLeadsTypeFilter,
  leadsFilterTypes,
} from '../../stores/profileSlice';
import { selectIsLoadingLeads, selectLeadsList, selectLeadOpened, updateLeadOpened } from '../../stores/leadsSlice';
import { checkLeadsData, saveLocalData } from '../../services';

import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';

import { LeadsContainerStyled } from './LeadsStyled';
import { EVENTS, LAYOUT, LEADS, SECTIONS_DATA } from '../../appConfig';
import HeaderCreditsInfos from './HeaderCreditsInfos';

import LeadItem from './LeadItem';

const { COLORS } = LAYOUT;

const { SECTIONS } = SECTIONS_DATA;

const { EVENTS_TYPES, globalEvents } = EVENTS;

const filtersTypesList = [
  {
    type: leadsFilterTypes.ALL,
    text: 'Todos os pedidos',
  },
  {
    type: leadsFilterTypes.OUTER_STATES,
    text: 'Somente Interestaduais',
  },
  {
    type: leadsFilterTypes.INNER_STATES,
    text: (
      <>
        Somente no
        <br />
        mesmo estado
      </>
    ),
  },
];

const Leads = ({ notificationLeadID }) => {
  const dispatch = useDispatch();
  const creditBalance = useSelector(selectCurrentBalance);
  const profileData = useSelector(selectProfileData);
  const leadsData = useSelector(selectLeadsList);
  const isLoading = useSelector(selectIsLoadingLeads);
  const leadOpened = useSelector(selectLeadOpened);
  const leadsFilterType = useSelector(selectLeadsTypeFilter);

  // const [countNotifications, setCountNotifications] = useState(0);
  const [leadsDataFiltered, setLeadsDataFiltered] = useState([]);

  useEffect(() => {
    document.addEventListener(EVENTS_TYPES.reloadLeads, onReloadLeads);
    return () => {
      document.removeEventListener(EVENTS_TYPES.reloadLeads, onReloadLeads);
    };
  }, [leadOpened]);

  useEffect(() => {
    if (notificationLeadID) onReloadLeads();
  }, [notificationLeadID]);

  useEffect(() => {
    if (leadsFilterType === undefined) {
      onUpdateLeadsTypeFilter(leadsFilterTypes.ALL);
      return;
    }

    // filtrando os pedidos com base no tipo de filtro selecionado
    if (leadsData?.length) {
      if (leadsFilterType === leadsFilterTypes.ALL) {
        setLeadsDataFiltered([...leadsData]);
      } else {
        setLeadsDataFiltered(
          leadsData.filter((lead) => {
            // recupera os dois últimos caracteres de lead.origem e lead.destino
            const ufFrom = lead.origem.slice(-2);
            const ufTo = lead.destino.slice(-2);

            return leadsFilterType === leadsFilterTypes.OUTER_STATES ? ufFrom !== ufTo : ufFrom === ufTo;
          })
        );
      }
    } else {
      setLeadsDataFiltered([]);
    }

    if (notificationLeadID && !leadOpened) {
      const lead = leadsData.find((lead) => lead.pedido === notificationLeadID);

      if (lead) {
        const { idpedido, custo, status } = lead;
        dispatch(updateLeadOpened(notificationLeadID));
        globalEvents.openLeadDetails(idpedido, custo, status);
      }
    }
  }, [leadsData]);

  useEffect(() => {
    onReloadLeads();
    document.addEventListener(EVENTS_TYPES.localNotificationReceived, onNotificationReceived);
    return () => {
      document.removeEventListener(EVENTS_TYPES.localNotificationReceived, onNotificationReceived);
    };
  }, []);

  // const openByPageOnSite = () => {
  //   const { cnpj } = profileData;
  //   window.open(URLS.URL_SITE_LOGIN + (cnpj ? '/' + cnpj : ''), '_blank');
  // };

  const onOpenBuyCredit = () => {
    globalEvents.navigateTo(SECTIONS.CreditsBuy);
  };

  const onNotificationReceived = useCallback(() => {
    // setCountNotifications((prevCount) => prevCount + 1);
  }, []); // Adicionando countNotifications como uma dependência

  const onReloadLeads = async () => {
    if (leadOpened) return;
    // setCountNotifications(0); // zera a contagam de notificações
    await checkLeadsData(dispatch, profileData);
  };

  const onUpdateLeadsTypeFilter = (type) => {
    // atualiza dados no redux
    dispatch(updateLeadsTypeFilter(type));
    // salva dados no localStorage
    saveLocalData(dispatch, { leadsProfileData: type });
    // recarrega os pedidos
    onReloadLeads();
  };

  return (
    <LeadsContainerStyled>
      <HeaderCreditsInfos
        creditBalance={creditBalance}
        onClickLeads={onReloadLeads}
        onClickBuyCredits={onOpenBuyCredit}
        filterData={{
          list: filtersTypesList,
          selected: leadsFilterType,
          onChange: onUpdateLeadsTypeFilter,
        }}
      />

      {/* Pedidos */}
      <div className="leads-container--content">
        {isLoading ? (
          <div className="content--is-loading">
            <UseAnimations
              size={100}
              animation={loading}
              strokeColor={COLORS.lead.grey_3}
            />
            <div>Aguarde, carregando pedidos...</div>
          </div>
        ) : (
          <>
            {leadsDataFiltered && leadsDataFiltered?.length ? (
              leadsDataFiltered.map((lead, idx) => (
                <LeadItem
                  data={lead}
                  key={lead.id + '_' + idx}
                />
              ))
            ) : (
              <div className="content--no-leads">
                {leadsDataFiltered?.length === 0 ? (
                  `Nenhum pedido localizado${
                    leadsFilterType === leadsFilterTypes.ALL
                      ? ''
                      : ' para mudanças ' +
                        (leadsFilterType === leadsFilterTypes.OUTER_STATES ? 'interestaduais' : 'no mesmo estado')
                  }!`
                ) : (
                  <>
                    Problemas ao carregar pedidos!
                    <br />
                    Tente novamente mais tarde.
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </LeadsContainerStyled>
  );
};

export default Leads;
