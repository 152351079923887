import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';

import { selectProfileData } from '../../../stores/profileSlice';
import API from '../../../services';
import { getAppToken } from '../../../services/firebase';

import { ModalM } from '../../../components/misc/muimm';
import { MdCircleNotifications } from 'react-icons/md';
import { ModalConfigNotificationStyled } from './ModalConfigNotificationStyled';

import { notificationStatus, getNotificationStatus } from '../../../services/firebase';

import { LAYOUT } from '../../../appConfig';

const { COLORS } = LAYOUT;

const ModalConfigNotification = ({ isLogged, onClose }) => {
  const profileData = useSelector(selectProfileData);

  const [isOpen, setIsOpen] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [notificationsPermission, setNotificationsPermission] = useState(getNotificationStatus());

  useEffect(() => {
    if (isLogged && notificationsPermission === notificationStatus.DEFAULT) {
      setIsOpen(true);
    }
  }, [isLogged, notificationsPermission]);

  const updateNotificationPermission = async () => {
    if (notificationsPermission === notificationStatus.DEFAULT) {
      setIsButtonClicked(true);
      const permission = await Notification.requestPermission();

      setNotificationsPermission(permission === 'granted' ? notificationStatus.GRANTED : notificationStatus.DENIED);

      if (permission === 'granted') {
        const fbAppToken = await getAppToken();
        const result = await API.validateLogin(profileData.cnpj, profileData.pass, fbAppToken);
      }

      setIsOpen(false);
    }
  };

  return (
    <ModalM
      isOpen={isOpen}
      onClose={onClose}
      padding="20px"
      width="80%"
    >
      <ModalConfigNotificationStyled>
        <div className="modal-confignot--icon">
          <MdCircleNotifications size={50} />
        </div>

        {isButtonClicked ? (
          <>
            <UseAnimations
              animation={loading}
              size={100}
              strokeColor={COLORS.lead.green_1}
            />

            <div className="modal-confignot--text">
              <div className="confignot-text--title">Aguardando confirmar permissão...</div>
            </div>
          </>
        ) : (
          <>
            <div className="modal-confignot--text">
              <div className="confignot-text--title">
                Quer receber alertas sobre novos pedidos de mudança em seu celular?
              </div>
              Clique no botão abaixo, e em seguida confirme a solicitação.
            </div>

            <Button
              variant="contained"
              style={{
                color: 'white',
                // backgroundColor: '#764AFF',
                backgroundColor: COLORS.lead.green_1,
                lineHeight: '1.3',
                borderWidth: '2px',
                padding: '15px 10px',
              }}
              onClick={updateNotificationPermission}
            >
              <div className="modal-confignot--btn">
                <b>CLIQUE AQUI</b> <span className="lc-text"> para ativar notificações de pedidos de mudança</span>
              </div>
            </Button>
          </>
        )}
      </ModalConfigNotificationStyled>
    </ModalM>
  );
};

export default ModalConfigNotification;
