import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { forEach } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// ícones
import LogoTruck from './components/misc/LogoTruck';

// import { BiSolidHelpCircle } from 'react-icons/bi'; // Ícone de help

// cofigurações
import { EVENTS, SECTIONS_DATA, CONNECTION_STATUSES } from './appConfig';
import { AppContainer, GlobalStyle } from './AppStyles';

// componentes
import SpashScreen from './components/SplashScreen';
import NotificationContent from './components/NotificationContent';
import MainContent from './components/MainContent';
import ModalLogout from './routes/root/components/ModalLogout';
import ModalConfigNotification from './routes/root/components/ModalConfigNotification';
import ModalBuyCredits from './routes/root/components/ModalBuyCredits';

// rotas
import Login from './routes/Login';
import Leads from './routes/Leads';
import DataConfig from './routes/DataConfig';
import Profile from './routes/Profile';
import CreditsBuy from './routes/CreditsBuy';

// services
import API, { checkLocalData, saveLocalData, doLogout } from './services';
import {
  updateCompanyData,
  updateCurrentBalance,
  upadateCreditsPackages,
  updatePurchaseBlocked,
  PROFILE_DEFAULT_DATA,
} from './stores/profileSlice';

// notficações
import {
  notificationStatus,
  getNotificationStatus,
  getAppToken,
  clearAppBadge,
  checkNotificationMessage,
} from './services/firebase';

// diversos
import { updateLeadOpened } from './stores/leadsSlice';
import { pwaToExternalPage, resetViewPort } from './uteis/helpers';

// configurações iniciais
const { EVENTS_TYPES, globalEvents } = EVENTS;
const { SECTIONS, SECTIONS_CONFIG } = SECTIONS_DATA;

// Se o dispositivo não tiver o recurso interno de Notificações,
// remove a seção de configurações por cidades
if (getNotificationStatus() === notificationStatus.NOT_SUPPORTED) delete SECTIONS_CONFIG[SECTIONS.Config];

const initialSection = SECTIONS.Leads;

function App() {
  const [hasInternetConnection, setHasInternetConnection] = useState(false);
  const [externalLogin, setExternalLogin] = useState(null);
  const [forcedLogout, setForcedLogout] = useState(null);
  const [isLogged, setIsLogged] = useState(null);
  const [isModalLogoutOpen, setIsModalLogoutOpen] = useState(false);
  const [isModalBuyCreditsOpen, setIsModalBuyCreditsOpen] = useState(false);
  const [buyCreditsStatusConnection, setBuyCreditsStatusConnection] = useState(null);
  const [buyCreditsErrorMessage, setBuyCreditsErrorMessage] = useState(null);
  const [section, setSection] = useState(initialSection);
  const [notificationLeadID, setNotificationLeadID] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    // controle de retorno ao app a partir de um History API
    const handleBackFromHistory = (event) => {
      if (pwaToExternalPage.isReturningToPWA()) {
        resetViewPort(true, () => {
          window.location.reload(true);
        });
      }
    };
    window.addEventListener('pageshow', handleBackFromHistory);

    ///////////////////////////////////////
    ///////////////////////////////////////

    const fmcChannelFocus = new BroadcastChannel(EVENTS_TYPES.mfcChannelFocus);
    const fmcChannelOpen = new BroadcastChannel(EVENTS_TYPES.mfcChannelOpen);

    // eventos padrão do navegador
    const visibilityChangeHandler = (event) => {
      if (document.visibilityState === 'visible') {
        handleDefaultFocus(event);
      }
    };
    window.addEventListener('focus', handleDefaultFocus);
    document.addEventListener('resume', handleDefaultFocus);
    document.addEventListener('visibilitychange', visibilityChangeHandler);

    // eventos personalizados do firebase
    fmcChannelFocus.addEventListener('message', handleNotificationFocus);
    fmcChannelOpen.addEventListener('message', handleNotificationFocus);

    // eventos personalizados do app
    document.addEventListener(EVENTS_TYPES.logIn, onLogin);
    document.addEventListener(EVENTS_TYPES.navigateTo, onChangeSection);
    document.addEventListener(EVENTS_TYPES.logOut, onLogout);
    document.addEventListener(EVENTS_TYPES.closeLeadDetails, resetNotificationLeadID);

    // evento de compra de créditos
    document.addEventListener(EVENTS_TYPES.connectToByCredits, onStartBuyCredits);
    document.addEventListener(EVENTS_TYPES.errorBuyCredits, onErrorBuyCredits);
    document.addEventListener(EVENTS_TYPES.finishBuyCredits, onCloseModalBuyCredits);

    return () => {
      window.removeEventListener('pageshow', handleBackFromHistory);
      ///
      window.removeEventListener('focus', handleDefaultFocus);
      document.removeEventListener('resume', handleDefaultFocus);
      document.removeEventListener('visibilitychange', visibilityChangeHandler);

      fmcChannelFocus.removeEventListener('message', handleNotificationFocus);
      fmcChannelOpen.removeEventListener('message', handleNotificationFocus);

      document.removeEventListener(EVENTS_TYPES.logIn, onLogin);
      document.removeEventListener(EVENTS_TYPES.navigateTo, onChangeSection);
      document.removeEventListener(EVENTS_TYPES.logOut, onLogout);
      document.removeEventListener(EVENTS_TYPES.closeLeadDetails, resetNotificationLeadID);

      document.removeEventListener(EVENTS_TYPES.connectToByCredits, onStartBuyCredits);
      document.removeEventListener(EVENTS_TYPES.errorBuyCredits, onErrorBuyCredits);
      document.removeEventListener(EVENTS_TYPES.finishBuyCredits, onCloseModalBuyCredits);
    };
  }, []);

  useEffect(() => {
    if (forcedLogout) setIsModalLogoutOpen(true);
  }, [forcedLogout]);

  useEffect(() => {
    // verifica se o usuário possui dados locais salvos
    onCheckLocalData();
  }, [hasInternetConnection]);

  useEffect(() => {
    if (buyCreditsStatusConnection === CONNECTION_STATUSES.sending) setIsModalBuyCreditsOpen(true);
  }, [buyCreditsStatusConnection]);

  useEffect(() => {
    if (isLogged) setSection(SECTIONS.Leads);
  }, [isLogged]);

  useEffect(() => {
    if (isLogged) {
      if (notificationLeadID) {
        setSection(SECTIONS.Leads);
      } else {
        dispatch(updateLeadOpened(null));
      }
    }
  }, [notificationLeadID]);

  // Notificações
  const resetNotificationLeadID = () => {
    dispatch(updateLeadOpened(null));
    setNotificationLeadID(null);
  };

  const handleNotificationFocus = (event) => {
    const { notificationLeadId } = event.data;
    if (notificationLeadId) setNotificationLeadID(notificationLeadId);
  };

  const handleDefaultFocus = (event) => {
    clearAppBadge();
    globalEvents.reloadLeads();
  };

  const onNewNotificationMessage = (message) => {
    const { title, body } = message.data;
    const [cityFrom, cityTo] = body.split(' > ');

    toast.info(
      <NotificationContent
        leadId={title}
        cityFrom={cityFrom}
        cityTo={cityTo}
      />,
      {
        icon: <LogoTruck />,
        position: 'top-center',
        closeOnClick: true,
        onClick: () => {
          setSection(SECTIONS.Leads);
        },
        progressClassName: 'progress-notification',
        bodyClassName: 'body-notification',
        className: 'class-notification',
      }
    );

    globalEvents.localNotificationReceived(title, cityFrom, cityTo);
  };

  const onCheckLocalData = async () => {
    if (!hasInternetConnection) return;

    const { profileData } = await checkLocalData(dispatch);

    if (profileData?.id) {
      const fbAppToken = await getAppToken();
      const result = await API.validateLogin(profileData.cnpj, profileData.pass, fbAppToken);

      if (result?.erro) {
        setIsLogged(false);
        setExternalLogin(result);
      } else {
        // atribui pass a result
        result.dadoslogin.pass = profileData.pass;
        globalEvents.logIn(result, true);
      }

      return;
    }

    setIsLogged(false);
  };

  const onChangeSection = (e) => {
    const sId = e?.detail?.sectionId ? e.detail.sectionId : typeof e === 'string' ? e : section;
    setSection(sId);
  };

  const onLogin = (e) => {
    const { data, autoLogin } = e.detail;
    const { dadosperfil, dadoslogin, dadoslocais, pacotesCreditos } = data;

    const {
      cnpjdados,
      bairro,
      cidade,
      complemento,
      compraBloqueada,
      contato,
      email,
      logradouro,
      nomeFantasia,
      razaoSocial,
      responsavel,
      saldoAtual,
      site,
    } = dadosperfil;

    // armazena dados da empresa na store
    dispatch(
      updateCompanyData({
        email,
        site,
        cnpjdados,
        name: nomeFantasia,
        corporateName: razaoSocial,
        owner: responsavel,
        contact: contato,
        address: logradouro,
        complement: complemento,
        district: bairro,
        city: cidade,
      })
    );

    dispatch(upadateCreditsPackages(pacotesCreditos?.pacotes || []));
    dispatch(updateCurrentBalance(parseInt(saldoAtual)));
    dispatch(updatePurchaseBlocked(parseInt(compraBloqueada)));

    // atualiza dados de localidades
    const locationsData = {};
    forEach(PROFILE_DEFAULT_DATA.locations, (value, key) => {
      if (dadoslocais[key]) {
        locationsData[key] = dadoslocais[key];
      } else {
        locationsData[key] = value;
      }
    });

    saveLocalData(dispatch, { locationsData });

    // Se o login foi disparado pelo usuário,
    // atualiza os dados do perfil e armazena no localstorage
    if (!autoLogin) {
      const { cnpj, id, senha } = dadoslogin;
      const profileData = {
        cnpj,
        id,
        pass: senha,
      };

      saveLocalData(dispatch, { profileData });
    }

    checkNotificationMessage(onNewNotificationMessage);

    setTimeout(() => {
      setIsLogged(true);
    }, 500);
  };

  const onLogout = (e) => {
    if (e?.detail?.byError) {
      setForcedLogout({ ...e.detail.byError });
      confirmLogout();
    } else {
      setIsModalLogoutOpen(true);
    }
  };

  const confirmLogout = () => {
    setIsLogged(false);
    setIsModalLogoutOpen(false);
    doLogout(dispatch);
  };

  const onStartBuyCredits = () => {
    setBuyCreditsStatusConnection(CONNECTION_STATUSES.sending);
  };

  const onErrorBuyCredits = (e) => {
    if (e?.detail?.errorMessage) setBuyCreditsErrorMessage(e.detail.errorMessage);
    setBuyCreditsStatusConnection(CONNECTION_STATUSES.error);
  };

  const onCloseModalBuyCredits = () => {
    setIsModalBuyCreditsOpen(false);
    setBuyCreditsStatusConnection(null);
    setBuyCreditsErrorMessage(null);
  };

  return (
    <>
      <AppContainer>
        {isLogged === true ? (
          <>
            <MainContent
              sectionsConfig={SECTIONS_CONFIG}
              section={section}
              colorBg={SECTIONS_CONFIG[section].colorBg}
            >
              {section === SECTIONS.Leads && (
                <Leads
                  notificationLeadID={notificationLeadID}
                  setNotificationLeadID={setNotificationLeadID}
                />
              )}
              {section === SECTIONS.CreditsBuy && <CreditsBuy />}
              {section === SECTIONS.Config && <DataConfig />}
              {section === SECTIONS.Profile && <Profile />}
            </MainContent>
          </>
        ) : isLogged === false ? (
          <Login externalLogin={externalLogin} />
        ) : (
          <SpashScreen
            checkConnection={API.checkConnection}
            setConnection={setHasInternetConnection}
          />
        )}
      </AppContainer>

      {/* Modal */}

      <ModalLogout
        isOpen={isModalLogoutOpen}
        onClose={() => {
          setIsModalLogoutOpen(false);
          setForcedLogout(null);
        }}
        onLogout={confirmLogout}
        forcedLogout={forcedLogout}
      />

      <ModalConfigNotification
        isLogged={isLogged}
        onClose={() => {}}
      />

      <ModalBuyCredits
        isOpen={isModalBuyCreditsOpen}
        onClose={onCloseModalBuyCredits}
        statusConnection={buyCreditsStatusConnection}
        errorMessage={buyCreditsErrorMessage}
      />

      {/* ToastContainer */}
      {isLogged && <ToastContainer autoClose={5000} />}

      <GlobalStyle />
    </>
  );
}

export default App;
